import React from "react"
import { Link } from "gatsby"
import css from "./DLThumbListItem.module.scss"

const DLThumbListItem = prop => {
  let thumbImg = prop.thumbImg.childImageSharp.fixed

  // 画像の解像度は DLZoomCanvas で決定される
  let src = thumbImg.src
  let clsThumbBorder = prop.thumbBorder ? css.withBorder : ""

  return (
    <div className={css.cWork}>
      <div className={css.cWork__fig + ` ` + clsThumbBorder}>
        <button onClick={prop.callbackClick} data-href={prop.linkTo}>
          <img src={src} alt={``} />
        </button>
      </div>
      <div className={css.cWork__meta}>
        <div className={css.cWork__meta__left}>
          <div className={css.cWork__title}>
            <Link
              to={prop.linkTo}
              state={{
                modal: true,
              }}
            >
              {prop.title}
            </Link>
          </div>
        </div>
        <div className={css.cWork__meta__right}>{prop.price}</div>
      </div>
      <div className={css.cWork__artist}>{prop.artistName}</div>
    </div>
  )
}

export default DLThumbListItem
