import React, { Component } from "react"
import css from "./Entrance.module.scss"
import Lottie from "react-lottie"
import * as animationData from "../images/lottie/3sBlockLogo.json"
import { graphql, StaticQuery } from "gatsby"

class Entrance extends Component {
  constructor(data) {
    super()
    this._siteMetadata = data.site.siteMetadata
    this._props = data.props
  }

  componentDidMount() {
    setTimeout(() => {
      this._props.onComplete()
    }, 4100)
  }

  render() {
    const _lottieOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      className: `ascrg`,
    }

    return (
      <div className={` js-entrance ` + css.base}>
        <p className={css.version}>Ver.{this._siteMetadata.version}</p>
        <div>
          <h1 className={css.logoBox}>
            <Lottie options={_lottieOptions} width={300} height={150} />
          </h1>
        </div>
      </div>
    )
  }
}
export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query SiteMetaData {
          site {
            siteMetadata {
              title
              siteUrl
              description
              descriptionEn
              author
              ogImage
              version
            }
          }
        }
      `}
      render={data => (
        <Entrance site={data.site} props={prop}>
          {prop.children}
        </Entrance>
      )}
    />
  )
}
