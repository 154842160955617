import React from "react"
import { Link } from "gatsby"
import css from "./DLThumbListAboutLink.module.scss"
import Lottie from "react-lottie"
import * as animationData from "../images/lottie/dlLogo.json"

const DLThumbListAboutLink = prop => {

  const _lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    className: `ascrg`
  }

  return (
    <div className={css.aboutLink}>
      <div className={css.aboutLink__logo}>
        <button onClick={prop.callbackClick} data-href={prop.linkTo}>
          <Lottie options={_lottieOptions} height={300} width={300} />
        </button>
      </div>
      <Link to={prop.linkTo}>About This Store</Link>
    </div>
  )
}

export default DLThumbListAboutLink
