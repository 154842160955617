import React, { Component } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DLZoomCanvas from "../../components/DLZoomCanvas"
import HeaderSub from "../../components/HeaderSub"
import Entrance from "../../components/Entrance"
import { withCookies, Cookies } from "react-cookie"
import { instanceOf } from "prop-types"

class IndexApp extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  constructor(props) {
    super(props)
    const { cookies } = props
    this._doCompleteEntrance = this._doCompleteEntrance.bind(this)
    this.state = {
      playEntrance: cookies.get("playEntrance") || "PLAY",
    }
  }

  _doCompleteEntrance() {
    this.setState(state => ({
      playEntrance: "DO_NOT_PLAY",
    }))
    const expireMinute = 5 // 5 分で Cookie の期限をきる -> カバーアニメーションが表示されないように
    const { cookies } = this.props
    const expires = new Date().getTime() + 60 * 1000 * expireMinute
    cookies.set("playEntrance", "DO_NOT_PLAY", {
      path: "/",
      expires: new Date(expires),
    })
  }

  render() {
    const TagEntrance =
      this.state.playEntrance === "PLAY" ? (
        <Entrance onComplete={this._doCompleteEntrance} />
      ) : (
        ""
      )

    const ZoomCanvas = <DLZoomCanvas lang={this.props.lang} />

    return (
      <Layout lang={this.props.lang}>
        <HeaderSub mode={`grid`} lang={this.props.lang} />
        <SEO title={`TRANS BOOKS DOWNLOADs`} lang={this.props.lang} />
        <div>{TagEntrance}</div>
        <div>{ZoomCanvas}</div>
      </Layout>
    )
  }
}

export default withCookies(IndexApp)
