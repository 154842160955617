import React, { Component } from "react"
import DLThumbListItem from "./DLThumbListItem"
import css from "./DLZoomCanvas.module.scss"
import { StaticQuery, graphql, navigate } from "gatsby"
import DLThumbListAboutLink from "./DLThumbListAboutLink"
import { Link } from "gatsby"

class DLZoomCanvas extends Component {
  static get contentWidth() {
    return 576 * 8
  }
  static get contentHeight() {
    return 576 * 8
  }

  static get MAX_ENTRIES() {
    return 64
  }

  constructor(data, lang) {
    super()
    this.data = data
    this.domContainer = React.createRef()
    this.domContent = React.createRef()
    this._reflow = this._reflow.bind(this)
    this._doZoomIn = this._doZoomIn.bind(this)
    this._doZoomOut = this._doZoomOut.bind(this)
    this._doResetZoom = this._doResetZoom.bind(this)
    this._onDestinyClick = this._onDestinyClick.bind(this)
    this.scroller = null
    this.state = {
      zoom: 1,
      grabbing: false,
      isDragged: false,
      isScrollerInitialized: false,
      randomUpdate: null, // shuffle を反映させるトリガーのための変数
      isRandomMode: false,
    }
    this._entries = []

    // vars
    this._touchDown = false
    this._grabbingStartPointX = null
    this._grabbingStartPointY = null
    this._FIRE_EVENT_DISTANCE = 15
    this._tmpTargetPath = null

    // binds
    this._doClickThumbnail = this._doClickThumbnail.bind(this)
    this._doClickAbout = this._doClickAbout.bind(this)

    // touch
    this._cbTouchStart = this._cbTouchStart.bind(this)
    this._cbTouchMove = this._cbTouchMove.bind(this)
    this._cbTouchEnd = this._cbTouchEnd.bind(this)
    this._cbTouchCancel = this._cbTouchCancel.bind(this)

    // mouse
    this._cbMouseDown = this._cbMouseDown.bind(this)
    this._cbMouseMove = this._cbMouseMove.bind(this)
    this._cbMouseUp = this._cbMouseUp.bind(this)
    this._cbMouseWheel = this._cbMouseWheel.bind(this)
  }

  /**
   * エントリーの作成
   */
  componentDidMount() {
    this._entries = []
    this.scroller = null
    this._queryPosts = []

    // 現在の言語に当てはまるエントリだけを抜き出す
    for (let i = 0; i < this.data.site.allMarkdownRemark.edges.length; i += 1) {
      let tmpEntries = this.data.site.allMarkdownRemark.edges[i]
      if (tmpEntries.node.fields.langKey === this.data.lang) {
        if (!tmpEntries.node.frontmatter.hidden) {
          this._queryPosts.push(tmpEntries)
        }
      }
    }

    let count = 0
    for (let i = 0; i < DLZoomCanvas.MAX_ENTRIES; i += 1) {
      let node = null
      let isEntry = true

      // About ページを混ぜ込む
      if (i % 30 === 0) {
        isEntry = false
        node = null
      } else {
        let n = count % this._queryPosts.length
        node = this._queryPosts[n].node
        isEntry = true
        count = count + 1
      }
      this._entries.push({
        isEntry: isEntry,
        node: node,
      })
    }

    // scroller が読み込まれているかを確認する
    this._checkInitScroller()
  }

  /**
   * unmount 時に参照を削除する
   */
  componentWillUnmount() {
    clearInterval(this._randInterval)
    window.removeEventListener("resize", this._reflow)
    this.domContainer.current.removeEventListener(
      "touchstart",
      this._cbTouchStart
    )
    document.removeEventListener("touchmove", this._cbTouchMove)
    document.removeEventListener("touchend", this._cbTouchEnd)
    document.removeEventListener("touchcancel", this._cbTouchCancel)

    this.domContainer.current.removeEventListener(
      "mousedown",
      this._cbMouseDown
    )
    document.removeEventListener("mousemove", this._cbMouseMove)
    document.removeEventListener("mouseup", this._cbMouseUp)
    this.domContainer.current.removeEventListener(
      navigator.userAgent.indexOf("Firefox") > -1
        ? "DOMMouseScroll"
        : "mousewheel",
      this._cbMouseWheel
    )
  }

  // ============================================================
  // function for scroller
  // ============================================================

  /**
   * 読み込まれるまでインターバルでチェック
   * @private
   */
  _checkInitScroller() {
    if (!this.state.isScrollerInitialized && window.Scroller) {
      this._doInitScroller()
      return
    }

    // console.log(`_checkInitScroller: cannot call window.Scroller`)

    this._scrollerInitTimeout = setTimeout(() => {
      this._checkInitScroller()
    }, 10)
  }

  /**
   * 読み込まれてから実行
   * @private
   */
  _doInitScroller() {
    clearTimeout(this._scrollerInitTimeout)

    this.setState(state => ({
      isScrollerInitialized: true,
    }))

    // Settings
    let container = this.domContainer.current
    this.scroller = new window.Scroller(window.render, {
      zooming: true,
      scrollingX: true,
      scrollingY: true,
      animating: false,
      bouncing: false,
      locking: true,
      minZoom: 0.1,
      maxZoom: 3,
      speedMultiplier: 1,
    })

    const rect = container.getBoundingClientRect()

    this.scroller.setPosition(
      rect.left + container.clientLeft,
      rect.top + container.clientTop
    )

    window.addEventListener("resize", this._reflow, false)
    this._reflow()

    if ("ontouchstart" in window) {
      this._initTouchEvents()
    } else {
      this._initDragEvents()
    }

    this._shufflePosts()
  }

  /**
   * Scroller のレイアウトを再設定
   *
   * リサイズ時などに呼ばれる
   * @private
   */
  _reflow() {
    if (!this.domContainer || !this.domContainer.current) {
      return
    }

    const clientWidth = this.domContainer.current.clientWidth
    const clientHeight = this.domContainer.current.clientHeight
    const contentWidth = DLZoomCanvas.contentWidth
    const contentHeight = DLZoomCanvas.contentHeight

    this.scroller.setDimensions(
      clientWidth,
      clientHeight,
      contentWidth,
      contentHeight
    )

    this.scroller.options.minZoom = Math.max(
      clientWidth / contentWidth,
      clientHeight / contentHeight
    )

    // あまりにも小さい場合は最小値で止める
    if (this.scroller.options.minZoom < 20) {
      this.scroller.zoomTo(0.2)
    } else {
      this.scroller.zoomTo(this.scroller.options.minZoom)
    }
    this.setState(state => ({
      zoom: this.scroller.getValues().zoom,
    }))
  }

  // ============================================================
  // callback functions for Touch Events
  // ============================================================

  _cbTouchStart(e) {
    // console.log(`_cbTouchStart`, e)
    this._doStopRandomMode()

    if (
      e.touches[0] &&
      e.touches[0].target &&
      e.touches[0].target.tagName.match(/input|textarea|select/i)
    ) {
      return
    }
    if (
      e.touches.length === 1 &&
      e.touches[0].target.tagName.match(/button/i)
    ) {
      this._touchDown = true
      this._grabbingStartPointX = e.touches[0].pageX
      this._grabbingStartPointY = e.touches[0].pageY
      this._tmpTargetPath = e.touches[0].target.dataset.href
    } else {
      this._tmpTargetPath = null
    }

    this.scroller.doTouchStart(e.touches, e.timeStamp)
    e.preventDefault()
  }

  _cbTouchMove(e) {
    // console.log(`_cbTouchMove`)
    this.scroller.doTouchMove(e.touches, e.timeStamp, e.scale)
    let startX = this._grabbingStartPointX
    let endX = e.touches[0].pageX
    let startY = this._grabbingStartPointY
    let endY = e.touches[0].pageY
    const distance = Math.sqrt(
      Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
    )
    this.setState(state => ({
      zoom: this.scroller.getValues().zoom,
      isDragged: distance > this._FIRE_EVENT_DISTANCE, // 15 px 以上マウスが移動していたらマウスムーブと判定する
    }))
  }

  _cbTouchEnd(e) {
    if (e.touches.length <= 0) {
      this._touchDown = false
      // ドラッグされた形跡がなく、かつ、ボタンをクリックしていたら
      if (this.state.isDragged === false && this._tmpTargetPath) {
        navigate(this._tmpTargetPath)
        e.preventDefault()
      }

      this.setState(state => ({
        isDragged: false,
      }))
    }

    this.scroller.doTouchEnd(e.timeStamp)
  }

  _cbTouchCancel(e) {
    this.scroller.doTouchEnd(e.timeStamp)
  }

  /**
   * タッチイベントの初期化
   * @private
   */
  _initTouchEvents() {
    // console.log(`add touch event`)
    this.domContainer.current.addEventListener(
      "touchstart",
      this._cbTouchStart,
      false
    )
    document.addEventListener("touchmove", this._cbTouchMove, false)
    document.addEventListener("touchend", this._cbTouchEnd, false)
    document.addEventListener("touchcancel", this._cbTouchCancel, false)
  }

  // ============================================================
  // callback functions for Mouse Events
  // ============================================================

  _cbMouseDown(e) {
    this._doStopRandomMode()
    if (e.target.tagName.match(/input|textarea|select/i)) {
      return
    }
    this.scroller.doTouchStart(
      [
        {
          pageX: e.pageX,
          pageY: e.pageY,
        },
      ],
      e.timeStamp
    )

    this._touchDown = true
    this._grabbingStartPointX = e.pageX
    this._grabbingStartPointY = e.pageY

    this.setState(state => ({
      grabbing: true,
      isDragged: false,
    }))
  }

  _cbMouseMove(e) {
    if (!this._touchDown) {
      return
    }
    this.scroller.doTouchMove(
      [
        {
          pageX: e.pageX,
          pageY: e.pageY,
        },
      ],
      e.timeStamp
    )

    this._touchDown = true
    let startX = this._grabbingStartPointX
    let endX = e.pageX
    let startY = this._grabbingStartPointY
    let endY = e.pageY
    const distance = Math.sqrt(
      Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
    )

    this.setState(state => ({
      grabbing: true,
      isDragged: distance > this._FIRE_EVENT_DISTANCE, // 15 px 以上マウスが移動していたらマウスムーブと判定する
    }))
  }

  _cbMouseUp(e) {
    if (!this._touchDown) {
      return
    }
    this.scroller.doTouchEnd(e.timeStamp)
    this._touchDown = false

    this.setState(state => ({
      grabbing: false,
    }))
  }

  _cbMouseWheel(e) {
    e.preventDefault()
    this._doStopRandomMode()

    this.scroller.doMouseZoom(
      e.detail ? e.detail * -120 : e.wheelDelta,
      e.timeStamp,
      e.pageX,
      e.pageY
    )
    clearInterval(this._randInterval)
    this.setState(state => ({
      zoom: this.scroller.getValues().zoom,
      isRandomMode: false,
    }))
  }

  /**
   * ドラッグイベントを初期化
   * @private
   */
  _initDragEvents() {
    this.domContainer.current.addEventListener(
      "mousedown",
      this._cbMouseDown,
      false
    )
    document.addEventListener("mousemove", this._cbMouseMove, false)
    document.addEventListener("mouseup", this._cbMouseUp, false)
    this.domContainer.current.addEventListener(
      navigator.userAgent.indexOf("Firefox") > -1
        ? "DOMMouseScroll"
        : "mousewheel",

      this._cbMouseWheel,
      false
    )
  }

  // ============================================================
  // callback functions for Zooming
  // ============================================================

  _doZoomIn() {
    this._doStopRandomMode()

    this.scroller.zoomBy(1.2, true) // zoom in
    this.setState(state => ({
      zoom: this.scroller.getValues().zoom,
    }))
  }

  _doZoomOut() {
    this._doStopRandomMode()

    this.scroller.zoomBy(0.8, true) // zoom out
    this.setState(state => ({
      zoom: this.scroller.getValues().zoom,
    }))
  }

  _doResetZoom() {
    this.scroller.zoomTo(1.0)
    this.setState(state => ({
      zoom: this.scroller.getValues().zoom,
    }))
  }

  // ============================================================
  // callback functions for thumb click events
  // ============================================================

  /**
   * サムネイルアイテムをクリックしたときの処理
   * @param e
   * @private
   */
  _doClickThumbnail(e) {
    if (!this.state.isDragged) {
      navigate(e.currentTarget.dataset.href, {
        state: {
          modal: true,
        },
      })
    }
  }

  _doClickAbout(e) {
    if (!this.state.isDragged) {
      navigate(e.currentTarget.dataset.href)
    }
  }

  // ============================================================
  // function for destiny
  // ============================================================
  /**
   * destiny ボタンのクリックでランダムモードをトグル
   * @private
   */
  _onDestinyClick() {
    if (this.state.isRandomMode) {
      this._doStopRandomMode()
    } else {
      this._doStartRandomMode()
    }
  }

  _doStartRandomMode() {
    this.setState(state => ({
      isRandomMode: true,
    }))
    this._doDestinyRandom()
  }

  _doStopRandomMode() {
    clearInterval(this._randInterval)
    this.setState(state => ({
      isRandomMode: false,
    }))
  }

  /**
   *
   * @param e
   * @private
   */
  _doDestinyRandom() {
    clearInterval(this._randInterval)
    this._randInterval = setInterval(() => {
      this._shufflePosts()
    }, 1000 / 12) // フレームレートが 10 を超えると destiny が押せなくなる
  }

  _shufflePosts() {
    this._entries.sort(function () {
      return Math.random() - 0.5
    })

    // １つ目を必ず about に
    let aboutEntry = null
    for (let i = 0; i < this._entries.length; i += 1) {
      const e = this._entries[i]
      if (e.isEntry === false) {
        aboutEntry = this._entries.splice(i, 1)[0]
        break
      }
    }
    this._entries.unshift(aboutEntry)

    this.setState(state => ({
      randomUpdate: Math.random(),
    }))
  }

  // ============================================================
  // function for finally render
  // ============================================================
  render() {
    const scale = Math.floor(this.state.zoom * 100)
    const classGrabbing = this.state.grabbing ? css.contentIsGrabbing : ""
    const langDir = this.data.lang === `en` ? `/en` : ``

    const clsDestiny = this.state.isRandomMode
      ? css.destinyButton + ` ` + css.destinyButtonActive
      : css.destinyButton
    return (
      <div>
        <p className={clsDestiny}>
          <button onClick={this._onDestinyClick}>
            <span role="img" aria-label="" className={css.destinyIcon}>
              💕
            </span>
            Destiny
          </button>
        </p>
        <ul className={css.zoomUi}>
          <li>
            <button className={css.zoomUi__plus} onClick={this._doZoomIn}>
              +
            </button>
          </li>
          <li>
            <button className={css.zoomUi__minus} onClick={this._doZoomOut}>
              -
            </button>
          </li>
          <li>
            <button className={css.zoomUi__scale} onClick={this._doResetZoom}>
              {scale}%
            </button>
          </li>
        </ul>
        <div id="container" className={css.container} ref={this.domContainer}>
          {this.data.lang === "ja" ? (
            <Link
              to={`/downloads/exhibition/`}
              onTouchStart={(e)=>{
                window.location.href="/downloads/exhibition/"
                e.preventDefault()
                return false
              }}
              role={`button`}
              className={css.linkToExhibition}
            >
              リアル展開催しました
            </Link>
          ) : (
            <Link
              to={`/en/downloads/exhibition/`}
              onTouchStart={(e)=>{
                window.location.href="/downloads/exhibition/"
                e.preventDefault()
                return false
              }}
              role={`button`}
              className={css.linkToExhibition}
            >
              Exhibition Information!
            </Link>
          )}
          <div
            id="content"
            className={css.content + ` ` + classGrabbing}
            ref={this.domContent}
            style={{
              width: DLZoomCanvas.contentWidth + `px`,
              height: DLZoomCanvas.contentHeight + `px`,
              willChange: "transform",
            }}
          >
            <div className={css.gridBox}>
              {this._entries.map((item, index) => {
                let html = (
                  <div className={css.gridBoxItem} key={index}>
                    <div className={css.gridBoxItem__inner}>
                      <DLThumbListAboutLink
                        linkTo={`${langDir}/downloads/about/`}
                        callbackClick={this._doClickAbout}
                      />
                    </div>
                  </div>
                )

                if (item.isEntry) {
                  return (
                    <div className={css.gridBoxItem} key={index}>
                      <div className={css.gridBoxItem__inner}>
                        <DLThumbListItem
                          title={item.node.frontmatter.title}
                          date={item.node.frontmatter.date}
                          artistName={item.node.frontmatter.artistName}
                          size={item.node.frontmatter.size}
                          sizeUnit={item.node.frontmatter.sizeUnit}
                          medium={item.node.frontmatter.medium}
                          thumbImg={item.node.frontmatter.thumbImg}
                          thumbBorder={item.node.frontmatter.thumbBorder}
                          linkTo={item.node.fields.slug}
                          price={item.node.frontmatter.price}
                          callbackClick={this._doClickThumbnail}
                        />
                      </div>
                    </div>
                  )
                } else {
                  return html
                }
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default prop => {
  const lang = !prop.lang ? `ja` : prop.lang
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  date
                  title
                  artistName
                  size
                  sizeUnit
                  medium
                  hidden
                  thumbImg {
                    childImageSharp {
                      fixed(width: 720, quality: 95) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  thumbBorder
                  price
                }
                fields {
                  slug
                  langKey
                }
                excerpt
                timeToRead
                html
                id
              }
            }
          }
        }
      `}
      render={data => (
        <DLZoomCanvas site={data} lang={lang}>
          {prop.children}
        </DLZoomCanvas>
      )}
    />
  )
}
